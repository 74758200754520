exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-category-js": () => import("./../../../src/pages/category.js" /* webpackChunkName: "component---src-pages-category-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-manga-list-js": () => import("./../../../src/pages/manga-list.js" /* webpackChunkName: "component---src-pages-manga-list-js" */),
  "component---src-pages-new-js": () => import("./../../../src/pages/new.js" /* webpackChunkName: "component---src-pages-new-js" */),
  "component---src-pages-popular-js": () => import("./../../../src/pages/popular.js" /* webpackChunkName: "component---src-pages-popular-js" */),
  "component---src-templates-blog-category-js": () => import("./../../../src/templates/blog-category.js" /* webpackChunkName: "component---src-templates-blog-category-js" */),
  "component---src-templates-blog-post-archive-js": () => import("./../../../src/templates/blog-post-archive.js" /* webpackChunkName: "component---src-templates-blog-post-archive-js" */),
  "component---src-templates-blog-post-category-js": () => import("./../../../src/templates/blog-post-category.js" /* webpackChunkName: "component---src-templates-blog-post-category-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

